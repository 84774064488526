import clsx from "clsx"
import Link from "next/link"
import { ProductPreviewType } from "types/global"
import Thumbnail from "../thumbnail"

const ProductPreview = ({
  title,
  handle,
  thumbnail,
  price,
  product,
}: ProductPreviewType) => {
  let brand = ""
  if("metadata" in product && "product_brand" in product.metadata) {
    brand = product.metadata.product_brand
  } else {
    brand = product.brand
  }
  return (
        <Link href={`/products/${handle}`}>
        <div>
          <Thumbnail thumbnail={thumbnail} size="full" />
          <div className="text-base-regular mt-2">
            <span className="text-stone-600 text-center" style={{display: "block"}}>{brand}</span>
            <span className="text-center flex justify-center">{title}</span>
            <div className="flex items-center gap-x-2 mt-1 text-center flex justify-center">
              {price ? (
                <>
                  {price.price_type === "sale" && (
                    <span className="line-through text-gray-500">
                      {price.original_price}
                    </span>
                  )}
                  <span
                    className={clsx("font-semibold", {
                      "text-rose-500": price.price_type === "sale",
                    })}
                  >
                    {price.calculated_price}
                  </span>
                </>
              ) : (
                <div className="w-20 h-6 animate-pulse bg-gray-100"></div>
              )}
            </div>
          </div>
        </div>
    </Link>
  )
}

export default ProductPreview
