import Head from "@modules/common/components/head"
import FeaturedProducts from "@byq/front-page/FeaturedProducts"
import Brands from "@byq/front-page/BrandsComponent"
import HeroComponent from "@byq/front-page/HeroComponent"
import Layout from "@modules/layout/templates"
import { ReactElement } from "react"
import { NextPageWithLayout } from "types/global"
import PopularDepartmentsComponent from "@byq/front-page/PopularDepartmentsComponent"
import Header from "@byq/layout/nav/Header"

const Home: NextPageWithLayout = () => {
  return (
    <>

    <div className="content-container">
      <Head
        title="Home"
        description=""
      />

      <HeroComponent />
      <Brands />
      <PopularDepartmentsComponent />
      <FeaturedProducts />
    </div>
    </>
  )
}

Home.getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>
}

export default Home
