import Image from "next/legacy/image"
import UnderlineLink from "@modules/common/components/underline-link"
import Link from "next/link"
import ArrowRight from "@modules/common/icons/arrow-right"
import { t } from "@byq/i8n"
import clsx from "clsx"

const BrandImage = ({imageName}) => {
  const imageSrc = `/brand_logos/${imageName}`
  const brandName = imageName.split(".")[0]
  const imageAlt = `Brand logo: ${brandName}`
  return (
    <Image
      src={imageSrc}

      loading="eager"
      priority={false}
      quality={90}
      width="100"
      height="40"
      alt={imageAlt}

      draggable="false"
    />
  )
}

const Brands = () => {
  // TODO: Add button show all brands, and have text saying we have x 1000s of brands
  const images = ["chanel.svg", "clinique.svg",
    //"dior.png",
    //"shiseido-ginza-tokyo.svg",
    "lancome.png", "versace.png", "estee_lauder.png", "yves_saint_laurent.png",
    //"sisley.svg",
    "calvin_klein.svg"]

  let counter = -1
  return (
    <div className="grid brands sm:mb-1">
      <div className="justify-center items-center text-center">
        <h1 className="text-xl mb-4 drop-shadow-md shadow-black">
          { t({en: "Selection of our brands", hr: "Izbor naših brendova"}) }
        </h1>
      </div>
      <div className="mt-4 sm:grid-cols-3 grid grid-cols-7 sm:gap-2 md:gap-8">
        { images.map((imageName, index) => {
          const cliniqueImg = imageName === "clinique.svg"
          return (
            <div key={index} className={clsx("", {
            "sm:mr-4": true,
            "sm:hidden": cliniqueImg,
          })}>
            <BrandImage imageName={imageName} />
          </div>
          )})}
      </div>
    </div>
  )
}




export default Brands
