import { t } from "@byq/i8n"
import UnderlineLink from "@modules/common/components/underline-link"
import Image from "next/legacy/image"
import Link from "next/link"

const departments = [
  {
    title: {en: "Perfume", hr:"Parfemi"},
    imgFilename: "fragrance.png",
    handle: "ljepota-parfemi-i-mirisi",
  },
  {
    title: {en: "Hair Care", hr: "Njeg Kose"},
    imgFilename: "haircare.png",
    handle: "proizvodi-za-njegu-kose-",
  },
  {
    title: {en: "Cosmetics", hr:"Kozmetika"},
    imgFilename: "makeup.png",
    handle: "kozmetika-",
  },
  {
    title: {en: "Body Care", hr:"Njega Tiela"},
    handle: "njega-tijela-",
    imgFilename: "skincare.png",
  },

]


const PopularDepartmentsComponent = () => {
  const imageDir = "/popular_departments/"
  return (
    <div className="grid md:mb-24 sm:mt-6 mt-20">
      <div className="sm:grid-cols-1 content-center">
        <h1 className="text-center text-xl mb-4 drop-shadow-md shadow-black">
          { t({en: "Popular departments", hr:"Popularni odjeli"}) }
        </h1>
      </div>
      <div className="grid sm:gap-8 sm:grid-cols-2 md:grid-flow-col md:auto-cols-max content-center justify-center sm:mt-0 md:mt-4">
        { departments.map(({title, imgFilename, handle}) => (
          <Link legacyBehavior key={handle} href={`/categories/${handle}`}>
            <a className="sm:col-span-1 sm:pl-3">
              <div className="sm:pt-4 sm:ml-0 ml-10">
                <h3 className="sm:pl-[23px] md:text-center">{ t(title) }</h3>

                <Image
                  className="mt-[120px] w-3!"
                  src={`${imageDir}${imgFilename}`}
                  loading="eager"
                  priority={true}
                  quality={90}
                  width="120"
                  height="100"
                  alt="Photo by @thevoncomplex https://unsplash.com/@thevoncomplex"

                  draggable="false"
                />

              </div>
            </a>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default PopularDepartmentsComponent
