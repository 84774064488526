import { t } from "@byq/i8n"
import UnderlineLink from "@modules/common/components/underline-link"
import Image from "next/image"

const Hero = () => {
  return (
    <div className="grid grid-cols-12 mb-12 sm:mb-20">
      <div className="sm:col-span-12 col-span-6 z-10 flex flex-col justify-center items-center text-center small:text-left small:justify-end small:items-start small:p-32 mt-24">
        <h1 className="text-2xl-semi mb-4 drop-shadow-md shadow-black">
          {t({en: "Shop the latest in Beauty with Leky", hr: "Kupujte najnovije proizvode ljepote s Leky"})}
        </h1>
        <p className="text-base-regular max-w-[32rem] mb-6 drop-shadow-md shadow-black">
          {t({en: "",hr: ""})}
        </p>
        <UnderlineLink href="/categories/ljepota-sminka">{t({en: "Explore products", hr: "Istražite proizvode"})}</UnderlineLink>
      </div>
      <div style={{position: "relative"}} className="sm:hidden col-span-4 !mt-[130px]">
        <Image src="/landing_images/girl_shopping.png" width={1600} height={1000} />

      </div>

    </div>
  )
}

export default Hero
